import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import moment from 'moment'
import UserService from "../services/UserService";

Vue.use(VueRouter)

// // https://lodash.com/docs/4.17.15
// var _ = require('lodash');
//

// kleines ISO Zeit formating  script
Vue.filter('isoLocalDateTime', function (value) {
    const d = moment.utc(value);
    return d.format('DD.MM.YYYY - HH:mm');
    // return d.local().format('DD.MM.YYYY - HH:mm');
//
});

// Text abschneiden
const filterTruncate = function (text, length, clamp) {
    clamp = clamp || '...';
    const node = document.createElement('div');
    node.innerHTML = text;
    const content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', filterTruncate);

// html entfernen
const filterHtml = function (text, length, clamp) {
    clamp = clamp || '...';
    const node = document.createElement('div');
    node.innerHTML = text;
    const content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('noHTML', filterHtml);


// kleines ISO Zeit formating  script
Vue.filter('kFormatter', function (value) {
    if (Math.abs(value) > 999) {
        return Math.sign(value) * ((Math.abs(value) / 1000).toFixed(1)) + 'k'
    } else {
        return Math.sign(value) * Math.abs(value)
    }
//
});


Vue.filter('sexyDomain', function (value) {
    let domain = (new URL(value));
    domain = domain.hostname;
    // domain = domain.hostname.replace('www.', '');
    return domain
});


const routes = [
    {
        path: '/market',
        name: 'Home',
        component: Home
    },
    {
        path: '/',
        name: 'start',
        component: () => import(/* webpackChunkName: "about" */ '../views/start.vue'),
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue'),
    },
    {
        path: '/create/:contractAddress?',
        name: 'CreateNft',
        component: () => import(/* webpackChunkName: "about" */ '../views/CreateNft.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(UserService.guest()())
            if (UserService.guest())
                return router.push("/login")
            return next()
        },
    },
    {
        path: '/transfer',
        name: 'TransferNft',
        component: () => import(/* webpackChunkName: "about" */ '../views/TransferNft.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(UserService.guest()())
            if (UserService.guest())
                return router.push("/login")
            return next()
        },
    },
    {
        path: '/sell',
        name: 'SellNFT',
        component: () => import(/* webpackChunkName: "about" */ '../views/SellNFT.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(UserService.guest()())
            if (UserService.guest())
                return router.push("/login")
            return next()
        },
    },
    {
        path: '/mynfts',
        name: 'MyNfts',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyNfts.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(UserService.guest()())
            if (UserService.guest())
                return router.push("/login")
            return next()
        },
    },
    {
        path: '/mycollections',
        name: 'MyCollections',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyCollections.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(UserService.guest()())
            if (UserService.guest())
                return router.push("/login")
            return next()
        },
    },
    {
        path: '/mylistings',
        name: 'MyListings',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyListings.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(UserService.guest()())
            if (UserService.guest())
                return router.push("/login")
            return next()
        },
    },

    {
        path: '/login',
        name: 'LoginRegister',
        component: () => import(/* webpackChunkName: "about" */ '../views/LoginRegister.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(UserService.guest()())
            if (UserService.check())
                return router.push("/")
            return next()
        },
    },
    {
        path: '/collections',
        name: 'Collections',
        component: () => import(/* webpackChunkName: "about" */ '../views/Collections.vue'),
        // beforeEnter: (to, from, next) => {
        //     // console.log(UserService.guest()())
        //     if (UserService.guest())
        //         return router.push("/login")
        //     return next()
        // },
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
        // beforeEnter: (to, from, next) => {
        //     // console.log(UserService.guest()())
        //     if (UserService.guest())
        //         return router.push("/login")
        //     return next()
        // },
    },



    {
        path: '/nfts/:id',
        name: 'nftSingle',
        component: () => import('../views/single/nftSingle.vue'),
    },
    {
        path: '/listings/:id',
        name: 'listingSingle',
        component: () => import('../views/single/listingSingle.vue'),
    },
    {
        path: '/users/:id',
        name: 'userSingle',
        component: () => import('../views/single/userSingle.vue'),
    },

    {
        path: '/collections/:id',
        name: 'collectionSingle',
        component: () => import('../views/single/collectionSingle.vue'),
        // beforeEnter: (to, from, next) => {
        //     // console.log(UserService.guest()())
        //     if (UserService.guest())
        //         return router.push("/login")
        //     return next()
        // },
    },


    {
        path: '/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "about" */ '../views/Help.vue'),

    },
    {
        path: '/DeployTraderContract',
        name: 'DeployTraderContract',
        component: () => import(/* webpackChunkName: "about" */ '../views/DeployTraderContract.vue'),

    },

    {
        path: '/addPolygonChain',
        name: 'addPolygonChain',
        component: () => import(/* webpackChunkName: "about" */ '../components/networkSettings/addMumbaiPolygonChain.vue'),

    },
    {
        path: '/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "about" */ '../views/News.vue'),

    },
    {
        path: '/beneficiaries',
        name: 'Beneficiaries',
        component: () => import(/* webpackChunkName: "about" */ '../views/Beneficiaries.vue'),

    },

    {
        path: '/DemoWeb3JS',
        name: 'DemoWeb3JS',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DemoWeb3JS.vue')
    },
    {
        path: '/DemoEtherJS',
        name: 'DemoEtherJS',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DemoEtherJS.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
