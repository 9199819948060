<template>
  <a :href="nftMeta.image" target="_blank"
     @click="copy(nftMeta.ImageBafyHash, 'you are redirected to the ipfs Gateway')" class="smallBtn">
    <img src="@/assets/ipfsImage.svg" alt="" title="Pin File on ipfs">
  </a>
</template>

<script>
export default {
  name: "ipfsProofImage",
  props: {
    nftMeta: Object
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    copy(string, text) {
      navigator.clipboard.writeText(string);
      alert(text + ': ' + string)
    }
  }
}

</script>

<style scoped>

</style>