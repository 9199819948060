<template>
  <div class="home">
    <h1 class="title">NFT Marketplace</h1>
    <div class="content">

      <!--      <h2>Overview Page</h2>-->

      <div class="container" v-if="listings.length > 0">
        <div :key="listing.id" v-for="listing in listings" class="nft_listing">
          <ListingOverview v-if="listing.nft" :inputListing="listing"
                           :key="listing.tokenId"/>
        </div>
      </div>
      <div v-else class="flexCenterCenter">
        <img class="illustration" src="@/assets/no_data.svg" alt="">
        <h2>No NFT on sale</h2>
      </div>
    </div>

    <!--    <img alt="Vue logo" src="../assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
import axios from "axios";
import Api from "../services/Api";
import ListingOverview from "../components/nft/listingOverview";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'Home',
  components: {
    ListingOverview,
    // HelloWorld
  },
  data() {
    return {
      listings: []
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      await this.getListings();
    },
    async getListings() {

      const response = await axios.get(Api.baseUrl + "/listings")
      // console.log(response.data);
      // this.listings = response.data

      let listingsModel = response.data

      for (let listing of listingsModel) {
        if (listing.nft_id) {
          const response2 = await axios.get(Api.baseUrl + "/nfts/" + listing.nft_id)
          listing.nft = response2.data[0]
          // console.log(response2)
        }
      }

      this.listings = listingsModel

    }
  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;
}




.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
