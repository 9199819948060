<template>
  <div>

    <a v-if="!metaMaskInstalled" target="_blank" href="https://metamask.io/download.html">
      <button class="cta showDesktop" style="margin-bottom: 0">
        Install MetaMask to use this Website
      </button>
    </a>
    <a v-if="!metaMaskInstalled" href="https://metamask.app.link/dapp/www.transfer-nft.com/" class="cta showMobile">
      <button class="cta" @click="checkIfAlreadyRegistered" style="margin-bottom: 0">Join</button>
    </a>

    <div v-else>
      <div class="account">
        <!--                {{ connectedNetwork.name }}-->
        <!--        {{ publicAddress }}-->


        <!--        <chain-switch-small :chain-id="connectedNetwork.chainId" :address="publicAddress"/>-->
        <chain-switch-small/>

        <!--        <div v-if="!UtilityService.desktopCheck()">-->
        <!--          <a href="https://metamask.app.link/dapp/www.transfer-nft.com/" class="cta">-->
        <!--            <button class="cta" @click="checkIfAlreadyRegistered">Open with MetaMask</button>-->
        <!--          </a>-->
        <!--        </div>-->
        <!--        <div v-else>-->
        <button v-if="!identicon" class="cta showDesktop" @click="checkIfAlreadyRegistered">Login with MetaMask</button>
        <button v-if="!identicon" class="cta showMobile" @click="checkIfAlreadyRegistered">Join</button>
        <!--        </div>-->

        <img v-if="identicon" class="identicon" @click="openWallet" :src="identicon" alt="">
      </div>
      <div class="card accountWrapper" v-if="toggleAccountInfos">

        <h2>
          Account Details:
        </h2>
        <div v-if="balance" class="card walletInfo">
          Your Account balance
          <b>{{ balance }} {{ChainApi.getNativeCurrencyByChainId(connectedNetwork.chainId)}}</b>
          <changelly/>
        </div>
        <div v-if="publicAddress" class="card walletInfo">
          Your Public Key
          <input type="text" :value="publicAddress" disabled>
        </div>

        <div class="card walletInfo" v-if="user">
          <b>{{ user.name }}</b>
          <p>{{ user.bio }}</p>
        </div>
        <br>
        <div class="accountActions">
          <button class="middleBtn" @click="toggleAccountInfos = !toggleAccountInfos"><img src="@/assets/close.svg"
                                                                                           alt=""></button>
          <button class="middleBtn" @click="toAccount"><img src="@/assets/account.svg" alt=""></button>
<!--          <router-link to="/help">-->
<!--            <button class="middleBtn">?</button>-->
<!--          </router-link>-->
          <button class="middleBtn" @click="logout"><img src="@/assets/logout.svg" alt=""></button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import UserService from "../../services/UserService";
import Api from "../../services/Api";
import ChainApi from "../../services/ChainApi";
import Changelly from "../external/changelly";
import ChainSwitchSmall from "../networkSettings/chainSwitchSmall";
import UtilityService from "../../services/UtilityService";

export default {
  name: "authMetamaskBtn",
  components: {ChainSwitchSmall, Changelly},
  data() {
    return {
      UtilityService: UtilityService,
      UserService: UserService,
      ChainApi: ChainApi,
      metaMaskInstalled: false,

      provider: null,
      signer: null,
      username: "",
      signatedNonce: null,
      publicAddress: null,

      identicon: null,

      toggleAccountInfos: false,
      balance: null,
      isMetaMask: false,
      // pastTransactions: null,

      connectedNetwork: null,
      user: null,
    }
  },
  mounted() {
    this.load()

  },
  activated() {
    this.load()
  },
  methods: {

    async openWallet() {
      this.toggleAccountInfos = !this.toggleAccountInfos
      if (this.toggleAccountInfos === true) {
        this.balance = await this.ChainApi.getBalance(this.signer)
        const response = await axios.get(Api.baseUrl + "/users/me/me")
        this.user = response.data
      }
    },

    // async getTransactions(publicAddress) {
    //
    //   this.pastTransactions = await this.ChainApi.getTransactions(publicAddress)
    //   console.log('pastTransactions')
    //   console.log(this.pastTransactions)
    // },

    async load() {
      this.metaMaskInstalled = await this.ChainApi.checkforMetaMask()
      await this.checkSession()
      await this.connectMetaMask()
    },


    async connectMetaMask() {
      this.provider = await this.ChainApi.connectMetaMask()
      await this.getNetwork() // Get connected Network data
      this.signer = await this.ChainApi.createSigner(this.provider)
      // this.pastTransactions = await this.getTransactions(this.publicAddress)
    },

    async checkSession() {
      const response = await this.UserService.checkSession()
      this.publicAddress = response.publicAddress
      await this.getIdeticon()
    },

    async logout() {
      await this.UserService.logout();
      this.provider = null
      this.signer = null
      this.username = ""
      this.signatedNonce = null
      this.publicAddress = null
      this.identicon = null
      this.connectedNetwork = null
      this.toggleAccountInfos = false
    },

    async authUser(signature, publicAddress) {
      await this.UserService.authUser(signature, publicAddress)
    },

    async checkIfAlreadyRegistered() {
      this.publicAddress = await this.signer.getAddress();
      await this.getIdeticon()


      const options = {
        method: 'GET',
        url: Api.baseUrl + '/users/account/' + this.publicAddress,
        headers: {'Content-Type': 'application/json'}
      };

      axios.request(options).then(async (response) => {
        console.log(response.data[0]);
        this.nonce = response.data[0].nonce
        await this.loginUser(this.publicAddress)
      }).catch(async (error) => {
        console.error(error);
        console.log('No User found Please Create one')
        await this.registerUser(this.publicAddress)
      });

    },

    async registerUser(publicAddress) {
      const response = await this.UserService.registerUser(publicAddress)
      await this.getUserFromDb(response)
    },

    async loginUser(publicAddress) {
      await this.getUserByPublicAddress(publicAddress)
    },

    async getUserFromDb(id) {
      const user = await this.UserService.getUser(id)
      await this.UserService.signMessage(user.nonce, this.signer, this.publicAddress)
    },

    async getUserByPublicAddress(publicAddress) {
      const user = await this.UserService.getUserByPublicAddress(publicAddress);
      await this.UserService.signMessage(user.nonce, this.signer, this.publicAddress)
    },

    async getIdeticon() {
      if (this.publicAddress) {
        this.identicon = await this.ChainApi.getIdenticon(this.publicAddress)
      }
    },

    async getNetwork() {
      this.connectedNetwork = await this.provider.getNetwork()
    },

    async toAccount() {
      this.toggleAccountInfos = !this.toggleAccountInfos
      await this.$router.push('/users/' + localStorage.getItem('userId'))
    }

  },
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';

.account {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 18px;
  height: 100%;

  .cta {
    //  border-radius: 0;
    margin-top: 13px;
    padding: 18px 24px;
    //height: 57px;
  }

  .identicon {
    margin-top: 0px;
    height: 57px;
    border-radius: 50%;
  }

}

.accountWrapper {
  position: fixed;
  max-width: 400px;
  background: white;
  right: 16px;
  top: 76px;
  box-shadow: 0 0 1000px 10000px rgba(0, 0, 0, 0.5);
  //height: 500px;
}

.walletInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.accountActions {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
</style>