<template>
  <div>
    <!--    <div v-if="address">{{ address }}</div>-->
    <iframe
        :src="'https://changelly.com/static/payment-button-widget/index.html?paymentButtonTheme=default&buttonTextId=001&widgetLink=https%3A%2F%2Fwidget.changelly.com%3Ffrom%3D*%26to%3D*%26amount%3D50%26address%3D'
        + address
        + '%26fromDefault%3Dusd%26toDefault%3Dmatic%26theme%3Ddefault%26merchant_id%3DRp4rI-04-bgc886n%26payment_id%3D%26v%3D3&isPopUp=true&tickerId=008'"
        width="220"
        height="70"
        frameborder="0"
        style="border-radius: 4px;">
    </iframe>
    <div id="changellyModal"></div>
  </div>
</template>

<script>
import ChainApi from "../../services/ChainApi";

export default {
  name: "changelly",
  data() {
    return {
      ChainApi: ChainApi,
      provider: null,
      address: '',
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.provider = await this.ChainApi.connectMetaMask()
      if (this.provider) {
        console.log(this.provider)
        const response = await this.provider.listAccounts();
        this.address = response[0]
      }

      // load widget script
      // let changelly = document.createElement('script')
      // changelly.setAttribute('src', 'https://changelly.com/static/payment-button-widget/widget-modal.js')
      // document.head.appendChild(changelly)
    }
  }
}
</script>

<style scoped>


#changellyModal {
  display: none;
  position: fixed;
  z-index: 3;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000;
  background-color: rgba(0, 0, 0, .7)
}

.changellyModal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 568px;
  height: 472px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: .4s;
  animation-name: animatetop;
  animation-duration: .4s
}

.changellyModal-close {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  float: right;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  right: 6px;
  top: 1px;
  z-index: 3
}

.changellyModal-close:hover, .changellyModal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer
}
</style>