<template>
  <div id="app">
    <NavBar class="navbarWrapper"></NavBar>
    <div class="mainContent">
      <SideBar v-if="sidebarToggle" class="sidebarWrapper"></SideBar>
      <div class="contentWrapper">
        <!--        <div>-->
        <transition>
          <router-view/>
        </transition>
        <br>
        <br>
        <footer-section class="fade-in"/>
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "./components/ui-components/navBar";
import SideBar from "./components/ui-components/sideBar"
import Api from "./services/Api";
import axios from 'axios'
import FooterSection from "./components/ui-components/footerSection";
import state from "./store/state";

export default {
  name: "NFT_plattform",
  components: {FooterSection, NavBar, SideBar},
  computed: {
    sidebarToggle() {
      return state.data.sidebarToggle
    }
  },
  data() {
    return {
      state: state,
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      // this.state.data.sidebarToggle = !this.state.data.sidebarToggle

    }
  },
  mounted() {
    // this.state.data.sidebarToggle = false
    this.getScreenWidth();
    window.addEventListener('resize', () => {
      this.getScreenWidth();
    });
  },
  created() {

    // console.log(this.state.data)

    axios.get(Api.baseUrl + '/checkSession').then(function (response) {
      // console.log(response.data);
      const status = response.data.session.loggedin
      if (status !== true || status == null) {
        localStorage.removeItem('userId')
        localStorage.removeItem('isUser')

        if (localStorage.getItem('session') !== 'false') {
          window.reload()
          localStorage.setItem('session', 'false')
        }
      }
    }).catch(function (error) {
      console.error(error);
    });
  },
  methods: {

//     checkPWA() {
// // Initialize deferredPrompt for use later to show browser install prompt.
//       window.addEventListener('beforeinstallprompt', (e) => {
// // Prevent the mini-infobar from appearing on mobile
//         e.preventDefault();
// // Stash the event so it can be triggered later.
// //         this.deferredPrompt = e;
// // Update UI notify the user they can install the PWA
// //         this.pwaCTAToggle = true;
// // Optionally, send analytics event that PWA install promo was shown.
// // console.log(`'beforeinstallprompt' event was fired.`);
//       });
//     },
    getScreenWidth() {

      let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      console.log(vw)
      if (vw < 992) {
        this.state.data.sidebarToggle = false
      } else {
        this.state.data.sidebarToggle = true
      }

    },
  }
}


</script>

<style lang="scss">
@import 'src/styles/style.scss';


.mainContent {
  display: flex;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: $big * 6;
  padding-bottom: $big * 6;
  background-color: var(--lightBackground);
  width: 100%;
  min-height: calc(100vh - #{$navBarHeight});
  border-top-left-radius: $big * 4;
  //align-items: center;
}

@media screen and (max-width: 900px) {
  h1.highlight {
    font-size: 50px !important;
  }
  .contentWrapper {
    padding: 20px !important;
  }
  .content {
    width: 100%;
    min-width: unset;
    max-width: 100vw;
    padding: 20px !important;
  }
}

.navbarWrapper {
  z-index: 99999999;
}

.sidebarWrapper {
  z-index: 1;
  min-width: 200px;
}
</style>
