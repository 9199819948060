<template>
  <div>
    <div class="navBar">
      <div class="logoWrapper">
        <router-link to="/" class="showDesktop">
          <img src="@/assets/transfer-nft.svg" alt="" style="height:50px;">
        </router-link>

        <div class="showMobile">
          <div class="middleBtn" v-if="state.data.sidebarToggle" @click="state.data.sidebarToggle = false">
            <img src="@/assets/burger_close.svg" alt="">
          </div>
          <div class="middleBtn" v-if="!state.data.sidebarToggle" @click="state.data.sidebarToggle = true">
            <img src="@/assets/burger.svg" alt="">
          </div>
        </div>
      </div>

      <div class="searchWrapper">
        <router-link to="/">
          <span class="badge alpha" title="THis ap is not ready to be used. Use at your own risk">Alpha</span>
        </router-link>
        <!--        https://metamask.app.link/dapp/www.transfer-nft.com/-->
        <!--                <input type="search" name="" id="" placeholder="Search NFT World">-->
        <!--                <button class="searchBtn">Search</button>-->
      </div>

      <div class="userMeta">
        <ThemeButton/>
        <!--        <changelly/>-->
        <authMetamaskBtn></authMetamaskBtn>
      </div>
    </div>
    <div class="navBarPlaceholder"></div>
  </div>

</template>

<script>
import authMetamaskBtn from "../auth/authMetamaskBtn";
import state from "../../store/state";
import ThemeButton from "./ThemeButton";
// import Changelly from "../external/changelly";

export default {
  name: "navBar",
  components: {
    ThemeButton,
    // Changelly,
    authMetamaskBtn
  },
  computed: {
    sidebarState() {
      return state.data
    }
  },
  data() {
    return {
      state: state
    }
  },
  methods: {
    toggleSidebar() {
      console.log(this.state.data.sidebarToggle)
      this.state.data.sidebarToggle = false;
      console.log(this.state.data.sidebarToggle)
    }
  },
  mounted() {
    console.log(state)
  },
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';


@media all and (max-width:900px){
  .searchWrapper {
    position: initial!important;
  }
}

.badge {
  border-radius: 27px;
  padding: 8px 16px;
  color: white;
  box-shadow: 3px 3px 11px #00000033;
}

.alpha {
  background-color: #d6941b;
}

.navBarPlaceholder {
  height: $navBarHeight;
}

.navBar {
  position: fixed;
  background-color: var(--white);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $big;
  padding: $big;
  height: $navBarHeight;
  z-index: 99999;
}

.logoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $big;
  padding: $big;
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $big;

  position: fixed;
  left: $sideBarWidth;

  input {
    width: 30vw
  }
}

.userMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $big;
}
</style>