<template>
  <div class="nftWrapper" v-if="mediaURL">
    <!--    {{mediaURL}}-->
    <div class="imgWrapper" :style="'height:'+height+'; width:'+width+';'">
      <img v-if="UtilityService.isImageFile(mediaURL)" :src="mediaURL" alt="">
    </div>
    <video :width="width" :height="height" v-if="UtilityService.isVideoFile(mediaURL)"
           :src="mediaURL" autoplay muted loop/>

    <model-obj :width="width" :height="height" :rotation="rotation"
               @on-load="onLoad" v-if="UtilityService.is3DObj(mediaURL)" :src="mediaURL"/>
    <ModelFbx :width="width" :height="height" :rotation="rotation"
              @on-load="onLoad" v-if="UtilityService.is3DFBX(mediaURL)" :src="mediaURL"/>
    <model-gltf :width="width" :height="height" :rotation="rotation"
                @on-load="onLoad" v-if="UtilityService.is3DGLTF(mediaURL)" :src="mediaURL"/>
  </div>

</template>

<script>
import UtilityService from "../../services/UtilityService";
import {ModelFbx, ModelGltf, ModelObj} from 'vue-3d-model';

export default {
  name: "nftDisplay",
  components: {ModelObj, ModelGltf, ModelFbx},
  props: {
    mediaURL: String,
    width: Number,
    height: Number,
  },
  data() {
    return {
      UtilityService: UtilityService,
      rotation: {
        x: -Math.PI / 2,
        y: 0,
        z: 0
      }
    }
  },
  methods: {
    onLoad() {
      this.rotate();
    },
    rotate() {
      this.rotation.z += 0.001;
      requestAnimationFrame(this.rotate);
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/style.scss';

.nftWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  video, image {
    width: 100%;
  }

  //  div {
  //    display: contents;
  //  }
}

.imgWrapper {

  img {
    width: 100%;
    height: auto;
  }
}
</style>