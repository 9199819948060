<template>
  <span>
    
    <a v-if="chainId === ChainApi.getNetworkId.mainnet.ethereum" :href="'https://etherscan.io/address/'+ address "
       target="_blank" class="smallBtn">
      <img v-if="!verify" src="@/assets/eth.svg" alt="" title="Explore on BlockChain">
      <img v-if="verify" src="@/assets/verify.svg" alt="" title="Click to verify">
    </a>

    <a v-if="chainId === ChainApi.getNetworkId.testnet.ethereumRopsten"
       :href="'https://ropsten.etherscan.io/address/'+ address "
       target="_blank" class="smallBtn">
      <img v-if="!verify" src="@/assets/eth_ropsten.svg" alt="" title="Explore on BlockChain">
      <img v-if="verify" src="@/assets/verify.svg" alt="" title="Click to verify">
    </a>

    <a v-if="chainId === ChainApi.getNetworkId.testnet.ethereumKintsugi"
       :href="'https://explorer.kintsugi.themerge.dev/address/'+ address "
       target="_blank" class="smallBtn">
      <img v-if="!verify" src="@/assets/eth_kintsugi.svg" alt="" title="Explore on BlockChain">
      <img v-if="verify" src="@/assets/verify.svg" alt="" title="Click to verify">
    </a>
    <a v-if="chainId === ChainApi.getNetworkId.testnet.ethereumLocal"
       :href="'https://explorer.kintsugi.themerge.dev/address/'+ address "
       target="_blank" class="smallBtn">
      <img v-if="!verify" src="@/assets/eth_local.svg" alt="" title="Explore on BlockChain">
      <img v-if="verify" src="@/assets/verify.svg" alt="" title="Click to verify">
    </a>



    <a v-if="chainId === ChainApi.getNetworkId.mainnet.polygon" :href="'https://polygonscan.com/address/'+ address "
       target="_blank" class="smallBtn">
      <img v-if="!verify" src="@/assets/polygon_matic.svg" alt="" title="Explore on BlockChain">
      <img v-if="verify" src="@/assets/verify.svg" alt="" title="Click to verify">
    </a>

    <a v-if="chainId === ChainApi.getNetworkId.testnet.polygon"
       :href="'https://mumbai.polygonscan.com/address/'+ address "
       target="_blank" class="smallBtn">
      <img v-if="!verify" src="@/assets/polygon_matic_mumbai.svg" alt="" title="Explore on BlockChain">
      <img v-if="verify" src="@/assets/verify.svg" alt="" title="Click to verify">
    </a>


    <a v-if="chainId === ChainApi.getNetworkId.mainnet.binanceSmartChain"
       :href="'https://bscscan.com/address/'+ address "
       target="_blank" class="smallBtn">
      <img v-if="!verify" src="@/assets/binance.svg" alt="" title="Explore on BlockChain">
      <img v-if="verify" src="@/assets/verify.svg" alt="" title="Click to verify">
    </a>

    <a v-if="chainId === ChainApi.getNetworkId.testnet.binanceSmartChain"
       :href="'https://testnet.bscscan.com/address/'+ address "
       target="_blank" class="smallBtn">
      <img v-if="!verify" src="@/assets/binance_testnet.svg" alt="" title="Explore on BlockChain">
      <img v-if="verify" src="@/assets/verify.svg" alt="" title="Click to verify">
    </a>
  </span>

</template>

<script>
import ChainApi from "../../services/ChainApi";

export default {
  name: "chainSymbolAddress",
  props: {
    chainId: Number,
    address: String,
    verify: Boolean,
  },
  data() {
    return {
      ChainApi: ChainApi,
    }
  }
}
</script>

<style scoped>

</style>