<template>
  <div class="card nftOverview">
    <div v-if="loading" @click="viewNft">
      <Loader/>
    </div>
    <div v-else-if="listing">

      <div class="nftImageWrapper">
        <div class="nftImage">
          <nft-display :mediaURL="listing.nft.meta.image"/>
        </div>
      </div>

      <div @click="viewNft">
        <h2 class="title">{{ listing.nft.meta.name }}</h2>
        <button class="cta">{{ ethPrice }} {{ChainApi.getNativeCurrencyByChainId(listing.chainId)}}</button>
        <p class="description">{{ listing.nft.meta.description | truncate(60) }}</p>
      </div>
      <proof-box :nft="listing.nft"/>
    </div>
    <div v-else-if="error" @click="viewNft">
      <p>Oh no! Something went wrong.</p><br>
      <button @click="init">retry</button>
    </div>
    <div v-else>
      {{ error }}
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import IpfsAPI from "../../services/IpfsAPI";
import UtilityService from "../../services/UtilityService";
import ProofBox from "../networkSettings/proofBox";
// import { ModelObj } from 'vue-3d-model';
import NftDisplay from "./nftDisplay";
// import GlbModel from "../ModelViewers/glbModel";
import ChainApi from "../../services/ChainApi";
import Loader from "../ui-components/loader";

export default {
  name: "listingOverview",
  components: {
    Loader,
    // GlbModel,
    NftDisplay, ProofBox,
    // ModelObj
  },
  props: {
    inputListing: Object,
  },
  data() {
    return {
      ChainApi: ChainApi,
      listing: null,
      IpfsAPI: IpfsAPI,
      UtilityService: UtilityService,
      loading: false,
      error: null,
      ipfsGateway: null,
      ethPrice: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {

      this.listing = this.inputListing
      await this.getEthPrice()

      this.loading = true
      this.listing.nft.meta = await this.IpfsAPI.getNFTMeta(this.listing.nft.nftURI)
      this.loading = false
    },
    async getEthPrice() {
      this.ethPrice = await this.ChainApi.convertWeiInETH(this.listing.price)
    },
    viewNft() {
      this.$router.push('/listings/' + this.listing.id)
    },
    copy(string) {
      navigator.clipboard.writeText(string);
      alert('Paste this BafyHash into your IPFS Destop search field and pin the files. Copied: ' + string)
    }
  },
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';


.nftOverview {
  min-height: 450px;
}

.title {
  min-height: 40px;
}

.description {
  min-height: 80px;
}

.card {
  background-color: #fff;
  position: relative;
}


</style>