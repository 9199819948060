import * as ethers from "ethers";
import makeBlockie from 'ethereum-blockies-base64';

class ChainApi {

    // Short List
    get getTraderContractDeployed() {
        return [
            this.getNetworkId.testnet.ethereumKintsugi,
            this.getNetworkId.testnet.ethereumLocal,
            this.getNetworkId.testnet.polygon,
            this.getNetworkId.testnet.binanceSmartChain,
        ]
    }

    get getDeployed() {
        return {
            mainnet: {
                ethereum: {
                    traderContract: '_not_deployed_atm_' // testnet
                    // traderContract: '_not_deployed_atm_' //mainnet
                },
                polygon: {
                    traderContract: "_not_deployed_atm_"  // testnet
                    // traderContract: ""  // mainnet
                },
                binanceSmartChain: {
                    traderContract: "_not_deployed_atm_" // testnet
                    // traderContract: "_not_deployed_atm_" // mainnet
                }

            },
            testnet: {
                ethereum: {
                    traderContract: '0xB2FA4de5e82f09072CFf31f4240a0800A1Cd32f8' // testnet v0.0
                    // traderContract: '0x43270f85A98c95EC33e3F4c52F81aA4E0E9E5Ca5' // testnet v0.0
                    // traderContract: '_not_deployed_atm_' //mainnet
                },

                ethereumRopsten: {
                    traderContract: "_________"  // testnet
                },
                ethereumKintsugi: {
                    traderContract: "0xb4b8b18a01078b529a0b0f00C2d5729D40CE58ab"  // testnet
                },
                ethereumLocal: {
                    traderContract: "_________"  // testnet
                },
                polygonMumbai: {
                    traderContract: "0xeEE0B10695E2Cf179C7F3Ab1Df431F2292ad2713"  // testnet
                },
                binanceSmartChain: {
                    traderContract: "0x47Ce18e83987095B2cF72c476c0d92dDC4eDDd59"  // testnet
                },
            },


        }
    }


    get getNetworkId() {
        return {
            mainnet: {
                ethereum: 1,
                polygon: 137,
                fantom: 250,
                binanceSmartChain: 56,
            },
            testnet: {
                ethereumRopsten: 3,
                ethereumKintsugi: 1337702,
                ethereumLocal: 1337,
                polygon: 80001,
                fantom: 4002,
                binanceSmartChain: 97,
            }
        }
    }

    get getAllowedNetworkSettings() {
        return {
            mainnet: {
                ethereum: {
                    name: "Ethereum Mainnet",
                    chain: "ETH",
                    network: "mainnet",
                    icon: "ethereum",
                    rpc: [
                        "https://api.mycryptoapi.com/eth",
                        "https://cloudflare-eth.com"
                    ],
                    faucets: [],
                    nativeCurrency: {
                        "name": "Ether",
                        "symbol": "ETH",
                        "decimals": 18
                    },
                    infoURL: "https://ethereum.org",
                    shortName: "eth",
                    chainId: this.toHex(this.getNetworkId.mainnet.ethereum),
                    networkId: this.getNetworkId.mainnet.ethereum,
                    slip44: 60,
                    ens: {
                        "registry": "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
                    },
                    explorers: [
                        {
                            "name": "etherscan",
                            "url": "https://etherscan.io",
                            "standard": "EIP3091"
                        }
                    ]
                },
                polygon: {
                    chainId: this.toHex(this.getNetworkId.mainnet.polygon),
                    chainName: "Polygon Mainnet",
                    symbol: 'MATIC',
                    nativeCurrency: {
                        // name: 'MATIC',
                        symbol: 'MATIC', // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls: ['https://polygon-rpc.com/'],
                    blockExplorerUrls: ['https://polygonscan.com/'],

                },
                fantom: {
                    chainId: this.toHex(this.getNetworkId.mainnet.fantom),
                    chainName: "Fantom Opera",
                    nativeCurrency: {
                        symbol: 'FTM', // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls: ['https://rpc.ftm.tools'],
                    blockExplorerUrls: ['https://ftmscan.com'],
                },
                binanceSmartChain: {
                    chainId: this.toHex(this.getNetworkId.mainnet.binanceSmartChain),
                    chainName: "Binance Smart Chain Mainnet",
                    nativeCurrency: {
                        "name": "Binance Chain Native Token",
                        "symbol": "BNB",
                        "decimals": 18
                    },
                    rpcUrls: ["https://bsc-dataseed1.binance.org",
                        "https://bsc-dataseed2.binance.org",
                        "https://bsc-dataseed3.binance.org",
                        "https://bsc-dataseed4.binance.org",
                        "https://bsc-dataseed1.defibit.io",
                        "https://bsc-dataseed2.defibit.io",
                        "https://bsc-dataseed3.defibit.io",
                        "https://bsc-dataseed4.defibit.io",
                        "https://bsc-dataseed1.ninicoin.io",
                        "https://bsc-dataseed2.ninicoin.io",
                        "https://bsc-dataseed3.ninicoin.io",
                        "https://bsc-dataseed4.ninicoin.io",
                        "wss://bsc-ws-node.nariox.org"],
                    blockExplorerUrls: ["https://bscscan.com/"],
                },
            },
            testnet: {
                // ethereum: {
                //     chainId: this.toHex(this.getNetworkId.testnet.ethereum)
                // },
                ethereumRopsten: {
                    chainId: this.toHex(this.getNetworkId.testnet.ethereumRopsten),
                    nativeCurrency: {
                        symbol: 'ETH',
                        decimals: 18,
                    },
                },
                ethereumKintsugi: {
                    chainId: this.toHex(this.getNetworkId.testnet.ethereumKintsugi),
                    chainName: "Kintsugi",
                    nativeCurrency: {
                        symbol: 'ETH',
                        decimals: 18,
                    },
                    rpcUrls: ['https://rpc.kintsugi.themerge.dev'],
                    blockExplorerUrls: ['https://explorer.kintsugi.themerge.dev'],
                    // https://kintsugi.themerge.dev/
                    // https://faucet.kintsugi.themerge.dev
                },
                ethereumLocal: {
                    chainId: this.toHex(this.getNetworkId.testnet.ethereumLocal)
                },
                polygon: {
                    chainId: this.toHex(this.getNetworkId.testnet.polygon),
                    chainName: "Polygon Testnet Mumbai",
                    // symbol: 'MATIC',
                    nativeCurrency: {
                        // name: 'MATIC',
                        symbol: 'MATIC', // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
                    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
                },
                fantom: {
                    chainId: this.toHex(this.getNetworkId.testnet.fantom),
                    chainName: "Fantom Testnet",
                    nativeCurrency: {
                        symbol: 'FTM', // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls: ['https://rpc.testnet.fantom.network/'],
                    blockExplorerUrls: ['https://testnet.ftmscan.com'],
                },
                binanceSmartChain: {
                    chainId: this.toHex(this.getNetworkId.testnet.binanceSmartChain),
                    chainName: "Binance Smart Chain Testnet",
                    // symbol: 'MATIC',
                    nativeCurrency: {
                        // name: 'MATIC',
                        symbol: 'tBNB', // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                    blockExplorerUrls: ['https://testnet.bscscan.com'],
                },
            }
        }
    }

    getTraderContractByChainId(chainId) {
        switch (chainId) {
            //Mainnets
            case this.getNetworkId.mainnet.ethereum:
                return this.getDeployed.mainnet.ethereum.traderContract
            case this.getNetworkId.mainnet.polygon:
                return this.getDeployed.mainnet.polygon.traderContract
            case this.getNetworkId.mainnet.binanceSmartChain:
                return this.getDeployed.mainnet.binanceSmartChain.traderContract

            case this.getNetworkId.testnet.ethereumRopsten:
                return this.getDeployed.testnet.ethereumRopsten.traderContract
            case this.getNetworkId.testnet.ethereumKintsugi:
                return this.getDeployed.testnet.ethereumKintsugi.traderContract
            case this.getNetworkId.testnet.polygon:
                return this.getDeployed.testnet.polygonMumbai.traderContract
            case this.getNetworkId.testnet.binanceSmartChain:
                return this.getDeployed.testnet.binanceSmartChain.traderContract

            default:
                return '_not_deployed_atm_'
        }
    }

    getNativeCurrencyByChainId(chainId) {
        switch (chainId) {
            //Mainnets
            case this.getNetworkId.mainnet.ethereum:
                return this.getAllowedNetworkSettings.mainnet.ethereum.nativeCurrency.symbol
            case this.getNetworkId.mainnet.polygon:
                return this.getAllowedNetworkSettings.mainnet.polygon.nativeCurrency.symbol
            case this.getNetworkId.mainnet.binanceSmartChain:
                return this.getAllowedNetworkSettings.mainnet.binanceSmartChain.nativeCurrency.symbol

            case this.getNetworkId.testnet.ethereumRopsten:
                return this.getAllowedNetworkSettings.testnet.ethereumRopsten.nativeCurrency.symbol
            case this.getNetworkId.testnet.ethereumKintsugi:
                return this.getAllowedNetworkSettings.testnet.ethereumKintsugi.nativeCurrency.symbol
            case this.getNetworkId.testnet.polygon:
                return this.getAllowedNetworkSettings.testnet.polygon.nativeCurrency.symbol
            case this.getNetworkId.testnet.binanceSmartChain:
                return this.getAllowedNetworkSettings.testnet.binanceSmartChain.nativeCurrency.symbol
            default:
                return 'Native Units'
        }
    }

    toHex(num) {
        return '0x' + num.toString(16)
    }

    async checkChain(provider, idToCheck) {
        // get networkSettings ID of connected Metamasc Chain Network
        console.log(provider)
        const connectedNetwork = await provider.getNetwork()

        /* List of allowed Chains */
        // const ethMainNet = 1 // Eth Homestead ETH Mainet
        // const maticMumbaiTestnet = 80001 // Mumbay testnet Matic Poligon

        if (connectedNetwork.chainId !== idToCheck) {
            console.error('Wrong Network', connectedNetwork);
            return false
        } else {
            console.log('network', connectedNetwork);
            return true
        }

    }

    async getChainId(provider) {
        // get networkSettings ID of connected Metamasc Chain Network
        console.log(provider)
        const network = await provider.getNetwork()
        return network.chainId
    }

    getBlockExplorer(chainId) {
        // Return the corresponding Blockexplorer URL for Frontend USE
        switch (chainId) {
            case 1:
                console.log("connected to ETH main net Homestead");
                return "https://etherscan.io/";
            case 137:
                console.log("Connected to Matic Mainnet (Polygon)");
                return "https://polygonscan.com/"
            case 80001:
                console.log("Connected to Mumbai Matic Testnet (Polygon)");
                return "https://mumbai.polygonscan.com/"
            case 123:
                console.log("some other supported networkSettings");
                break;
            default:
                console.log("Sorry, we dont support the connectet Chain (in Metamsak) with this networkSettings id:" + chainId + ".");
        }

    }

    getIpfsGateway() {

    }


    checkforMetaMask() {
        return window.ethereum.isMetaMask
    }

    async getTransactions(publicAddress) {
        // oly works on Main net or a right ETH address
        // console.log(publicAddress)
        let provider = new ethers.providers.EtherscanProvider();
        return await provider.getHistory(publicAddress);
    }

    async getIdenticon(publicAddress) {
        return makeBlockie(publicAddress);
    }

    async connectMetaMask() {
        // Alternativen Provider verbinden (Ohne metamask, z.B nur privat key uns Publikc key)
        // const url = "HTTP://127.0.0.1:7545";
        // this.provider = new ethers.providers.JsonRpcProvider(url);
        // console.log(this.provider);
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        // await this.checkChain(provider)
        return provider
    }

    async createSigner(provider) {
        // ethers (create random new account (Neues Wallet erstellen)
        // this.signer = ethers.Wallet.createRandom();

        // ethers (connect to JSON-RPC accounts) (Meta mask account Einloggen)
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        // console.log(signer)
        return signer
        // console.log("Account:", );

    }

    async getPublicAddress(signer) {
        return await signer.getAddress()
    }

    async getCollectionNftCount(collectionContract) {
        const nftCount = await collectionContract.getNftCount()
        console.log(nftCount.toNumber() + ' Nfts are minted from this Collection ontract')
        // console.log(nftCount)
        return nftCount.toNumber()
    }

    async getBalance(signer) {
        const resp = await signer.getBalance()
        const BalanceInEther = ethers.utils.formatEther(resp)
        // console.log(BalanceInEther)
        return BalanceInEther
    }

    async deployContract(abi, bytecode, signer, ...constructorArgs) {

        console.log('... preparing contract')
        const factory = new ethers.ContractFactory(abi, bytecode, signer);
        console.log('... deploying contract')
        const contract = await factory.deploy(...constructorArgs);

        console.log('... wait for contract creation transaction to be mined')
        const resp = await contract.deployTransaction.wait()

        console.log('=> Contract creation transaction is mined')
        console.log(resp)
        return resp

    }

    async getContract(contractAddress, abi, signer) {
        // (wird nur gebraucht wenn cortact bereits Deployed wurde, un nicht mehr im VueJs Stat ist)

        // Funktionen die was speichern oder machen brauchen einen Signer
        const contract = new ethers.Contract(contractAddress, abi, signer); // this.provider for Readonly acsess
        // console.log(contract)
        return contract
    }


// async getBlock(blockHashOrBlockNumber) {
//     // Block holen mit Transactions Refferenzen
//     // this.selectedBlock = await this.provider.getBlock(blockHashOrBlockNumber)
//
//     // Block Holen mit Transaktionen eingebettet (Erspart mehrere calls)
//     this.selectedBlock = await this.provider.getBlockWithTransactions(blockHashOrBlockNumber)
//     console.log(this.selectedBlock)
// }

    async getTransaction(provider, transaction) {
        const selectedTransaction = await provider.getTransaction(transaction.hash)
        console.log(selectedTransaction)
        return selectedTransaction
    }

    async convertEthInWei(etherApountString) {
        const wei = await ethers.utils.parseEther(etherApountString);
        // let wei = ethers.utils.parseEther('1000.0');
        const weiAsNumber = wei.toString(10)
        console.log(weiAsNumber);
        return weiAsNumber
// "1000000000000000000000"
    }

    async getBignumberWei(wei) {
        return ethers.utils.parseUnits(wei.toString(), "wei");
    }

    async convertWeiInETH(weiAmountString) {
        console.log(weiAmountString)


        const bigNumberInWei = await ethers.utils.parseUnits(weiAmountString.toString(), "wei")
        // console.log(bigNumberInWei)


        const valueInEth = await ethers.utils.formatEther(bigNumberInWei);
        // const value = await ethers.utils.formatUnits(bigNumber,"wei");

        // console.log(valueInEth);
        return valueInEth
// "1000.0"
    }

// async getTransactionReceipt(transaction) {
//     this.selectedTransactionReceipt = await this.provider.getTransactionReceipt(transaction.hash)
//     console.log(this.selectedTransactionReceipt)
// }
//
}

export default new ChainApi()