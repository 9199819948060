<template>
  <div>
    <!--    <div class="sidebarPlaceholder"></div>-->
    <ul class="sideBar">

      <h2 class="navTitle">Explore</h2>
      <!--      <li>-->
      <!--        <router-link to="/">Home</router-link>-->
      <!--      </li>-->
      <li>
        <router-link to="/market">Market</router-link>
      </li>
      <li>
        <router-link to="/gallery">Gallery</router-link>
      </li>
      <li>
        <router-link to="/collections">Collections</router-link>
      </li>

      <h2 class="navTitle">People</h2>
      <li>
        <router-link to="/users">Users / Artists</router-link>
      </li>

      <li>
        <router-link to="/beneficiaries">Beneficiaries</router-link>
      </li>

      <h2 class="navTitle">Actions</h2>
      <div v-if="UserService.check()">

        <li style="max-width: 200px;">
          <router-link to="/create">
            Create NFT
          </router-link>
        </li>
        <li>
          <router-link to="/sell">Sell NFT</router-link>
        </li>
<!--        <li>-->
<!--          <router-link to="/transfer">Transfer NFT</router-link>-->
<!--        </li>-->
        <!--      <li>-->
        <!--        <router-link to="/transfer">Transfer NFT</router-link>-->
        <!--      </li>-->

        <h2 class="navTitle">Your Assets</h2>
        <li>
          <router-link to="/mycollections">My Collections</router-link>
        </li>
        <li>
          <router-link to="/mynfts">My NFT</router-link>
        </li>
        <li>
          <router-link to="/mylistings">My Listings</router-link>
        </li>

      </div>

      <li v-else style="max-width: 200px;">
        <router-link to="/login">Register / Login</router-link>
      </li>

      <li style="max-width: 200px;">
        <router-link to="/help">Help</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import UserService from '../../services/UserService';

export default {
  name: "sideBar",
  data() {return {UserService: UserService}}
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';


.navTitle {
  color: gray;
  margin: $big 0 $small 0;

  font-size: 14px;
  opacity: 0.4;
}

a {
  font-weight: bold;
  color: #2c3e50;

  &.router-link-exact-active {
    color: #42b983;
  }
}

.sideBar {
  position: fixed;
  width: $sideBarWidth;
  //min-height: available;
  padding: $big;
}

@media all and (max-width: 900px) {


  .sidebarWrapper {
    position: fixed;
    width: 100%;
  }
  .sideBar {
    position: fixed;
    width: 100%;
    padding: 16px;
    background: var(--light-background);
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    //*{
    //  background: wheat;
    //}
  }
}

.sidebarPlaceholder {
  //position: fixed;
  min-width: $sideBarWidth;
  //min-height: available;
  padding: $big;
}

ul {
  padding: $big;

  li {
    padding: $small;

  }

}

</style>