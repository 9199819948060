<template>
  <span class="label">
    <chain-symbol-token v-if="collection && nft" :chain-id="nft.chainId"
                        :token="collection.collectionAddress +'?a='+ nft.id"/>
    <ipfs-proof-meta :nft-meta="nft.meta"/>
    <ipfs-proof-image :nft-meta="nft.meta"/>
  </span>
</template>

<script>

import IpfsProofMeta from "./ipfsProofMeta";
import IpfsProofImage from "./ipfsProofImage";
// import ChainSymbolTx from "./chainSymbolTx";
import ChainSymbolToken from "./chainSymbolToken";
import axios from "axios";
import Api from "../../services/Api";

export default {
  name: "proofBox",
  components: {
    ChainSymbolToken,
    IpfsProofImage, IpfsProofMeta
  },
  props: {
    nft: Object
  },
  data() {
    return {
      collection: null,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    copy(string, text) {
      navigator.clipboard.writeText(string);
      alert(text + ': ' + string)
    },
    async load() {
      await this.getCollection(this.nft.collection_id)
    },
    async getCollection(id) {
      const response = await axios.get(Api.baseUrl + "/collections/" + id)
      this.collection = response.data[0]
    },

  }
}
</script>

<style scoped>

</style>