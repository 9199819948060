import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
// In main.js

Vue.config.productionTip = false
axios.defaults.withCredentials = true
window.axios = axios

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
