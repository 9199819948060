<template>
  <div>
<!--    <!- <Newsletter/>&ndash;&gt;-->
    <div class="content">
      <h2>General Information</h2>
      <p>The use of the platform is at your own risk. We do not replace lost or blocked NFT or cryptocurrencies</p>
      <br>
      <div class="card col4">
        <div>
          <h2>Community</h2>
          <a href="" target="_blank"><p>Discord</p></a>
          <p>Telegram</p>
          <p>Linkedin</p>
          <p>Apply for Home Page Featuring</p>
        </div>
        <div>
          <h2>About Us</h2>
          <p>History of Transfer NFT</p>
          <p>Career</p>
          <p>Business Contacts</p>
          <p>Announcements</p>
          <p>Notices</p>
          <p>Privacy</p>
          <p>Imprint</p>
        </div>

<!--        <!-     <div>&ndash;&gt;-->
<!--        <!-       <h2>Products</h2>&ndash;&gt;-->
<!--        <!-       <p>Binance Tools</p>&ndash;&gt;-->
<!--        <!-     </div>&ndash;&gt;-->
        <div>
          <h2>Support</h2>
          <p>FAQ</p>
          <p>StackOverflow</p>
          <p>StackExchange</p>
          <p>E-Mail</p>
        </div>
        <div>
          <h2>Learn basics</h2>
          <p>What is BlockChain</p>
          <p>What is a SmartContract</p>
          <p>What is a NFT Collection</p>
          <p>What is a NFT </p>
          <p>What is IPFS</p>
          <p>What is Multichain</p>
          <p>How to Verify NFT</p>
          <p>Witch file format for NFT</p>
<!--<!-         <p>More</p>&ndash;&gt;-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Newsletter from "./newsletter";
export default {
  name: "footerSection",
  // components: {Newsletter}
}
</script>

<style scoped>

</style>