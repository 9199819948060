class Api {
    get baseUrl() {
        // check if in Production env
        if (window.location.hostname === 'localhost') {
            return 'http://localhost:3000'
        } else {
            // todo Brauch noch SSL
            // return 'http://transfernftbackend-env.eba-kznzvxp6.eu-west-1.elasticbeanstalk.com'
            return 'https://backend.transfer-nft.com'
        }
    }
}

export default new Api()