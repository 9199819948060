// import {makeAutoObservable} from "mobx"

class State {

    // constructor() {
    //     makeAutoObservable(this)
    // }


    data = {
        sidebarToggle: true,
    }


}

export default new State()