import axios from "axios";

class IpfsAPI {
    async getNFTMeta(uri) {
        // Verschiede ipfs Gateway anbieter
        // Hier gibt es noch mehr:
        // https://ipfs.github.io/public-gateway-checker/
        // Man sollte seine eigene Node Verwenden (Eigenes Gateway erstellen)
        // Oder auf der webseite einen Gateway Service Worker erstellen

        // const ipfsGateway = 'http://127.0.0.1:8081/ipfs/'
        // const ipfsGateway = 'https://ipfs.adatools.io/ipfs/'
        const ipfsGateway = 'https://ipfs.io/ipfs/'

        // const ipfsGateway = 'https://cf-ipfs.com/ipfs/'
        // const ipfsGateway = 'https://ipfs.best-practice.se/ipfs/'

        // Best but no video streaming
        // const ipfsGateway = 'https://cloudflare-ipfs.com/ipfs/'
        // const ipfsGateway = 'https://ipfs.cf-ipfs.com/ipfs/'
        // const ipfsGateway = 'https://gateway.ipfs.io/ipfs/'
        // const ipfsGateway = 'https://10.via0.com/ipfs/'
        // const ipfsGateway = 'https://gateway.pinata.cloud/ipfs/'
        // const ipfsGateway = 'https://ipfs.io/ipfs/'


        const uriWithGatheway = ipfsGateway + uri.split('ipfs://')[1]
        // const uriWithGatheway = uri

        const resp = await axios.get(uriWithGatheway, {withCredentials: false})

        // console.log('Got Meta data ...')
        // console.log(resp.data)
        let meta = resp.data

        // console.log('Got IMG url from Meta data ...')
        // clean the string ( HTTP Gatewahy)
        const cleanImageURI = resp.data.image.split('ipfs://')[1]
        meta.image = ipfsGateway + cleanImageURI

        meta.metaLink = ipfsGateway + uri.split('ipfs://')[1]
        meta.uri = uri.split('ipfs://')[1]
        // meta.image = 'https://s3.eu-central-1.wasabisys.com/openbucket/support/Mediathek%20verwalten/Bildrecherche.mp4'

        meta.ImageBafyHash = await this.getNFTMetaBafyHash(cleanImageURI)
        meta.MetaBafyHash = await this.getNFTMetaBafyHash(uri)
        // console.log(meta)
        return meta
    }

    async getNFTMetaBafyHash(uri) {
        // console.log(uri)
        let withoutProtocol
        if (uri.includes('ipfs://')) {
            withoutProtocol = uri.split('ipfs://')[1]
            // console.log(withoutProtocol)
        } else {
            withoutProtocol = uri
            // console.log(withoutProtocol)
        }

        let clean = withoutProtocol.split('/')[0]
        // console.log(clean)
        return clean
    }
}

export default new IpfsAPI()
