<template>
  <div class="switchWrapper" v-if="connectedNetwork">
    <!--    <span @click="toggle = !toggle">Blockchain connected</span>-->

    <div @click="toggle.select = !toggle.select">
      <chain-symbol-address-no-link :chain-id="connectedNetwork.chainId"/>
    </div>

    <div
        v-if="
          connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.ethereum
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.ethereumRopsten
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.ethereumKintsugi
          && connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.polygon
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.polygon
          && connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.binanceSmartChain
          && connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.binanceSmartChain"
        class="smallBtn" @click="toggle.notSupported = true; toggle.select = true">
      !
    </div>

    <div v-if="toggle.select" class="chainSwitcher">


        <!--      Ethereum -->
        <div v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.ethereum" class="smallBtn"
             @click="switchChains(ethereumHomestead)">
          <img src="@/assets/eth.svg" alt="" title="Ethereum Mainnet">
        </div>

        <!--      Polygon -->
        <div v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.polygon" class="smallBtn"
             @click="switchChains(polygon)">
          <img src="@/assets/polygon_matic.svg" alt="" title="Poligon">
        </div>

        <!-- Binance Smart Chain -->
        <div v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.mainnet.binanceSmartChain" class="smallBtn"
             @click="switchChains(binanceSmartChain)">
          <img src="@/assets/binance.svg" alt="" title="Binance Smart Chain">
        </div>


      <div style="color: gray">|</div>

        <!--      Testnet's-->

        <div v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.polygon" class="smallBtn"
             @click="switchChains(polygonMumbai)">
          <img src="@/assets/polygon_matic_mumbai.svg" alt="" title="Poligon Mumbai Testnet">
        </div>


        <div v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.ethereumRopsten" class="smallBtn"
             @click="switchChains(ethereumRopsten)">
          <img src="@/assets/eth_ropsten.svg" alt="" title="Ethereum Ropsten">
        </div>

        <div v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.ethereumKintsugi" class="smallBtn"
             @click="switchChains(ethereumKintsugi)">
          <img src="@/assets/eth_kintsugi.svg" alt="" title="Ethereum Kintsugi">
        </div>

        <!--      <div v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.ethereum" class="smallBtn"-->
        <!--           @click="switchChains(localTestNet)">-->
        <!--        <img src="@/assets/logo.png" width="30px" alt="" title="Binance Smart Chain">-->
        <!--      </div>-->


        <div v-if="connectedNetwork.chainId !== ChainApi.getNetworkId.testnet.binanceSmartChain" class="smallBtn"
             @click="switchChains(binanceSmartChainTestnet)">
          <img src="@/assets/binance_testnet.svg" alt="" title="Binance Smart Chain Testnet">
        </div>


        <div class="popup" v-if="toggle.notSupported">
          <div class="card popupContent">
            <h2>This chain is not Supported</h2>
            <p>You are connected to a Block chain witch is not supported by our Pattform. Click on the chain icon to
              select another Blockchain.</p>
            <button class="cta" @click="toggle.notSupported = false">close popup</button>
          </div>
        </div>

      </div>



    <chain-symbol-address v-if="address" :verify="true" :address="address" :chain-id="connectedNetwork.chainId"/>
    <chain-symbol-tx v-if="tx" :verify="true" :tx="tx" :chain-id="connectedNetwork.chainId"/>
    <chain-symbol-token v-if="token" :verify="true" :token="token" :chain-id="connectedNetwork.chainId"/>

  </div>

</template>

<script>
import ChainSymbolAddressNoLink from "./chainSymbolAddressNoLink";
import ChainApi from "../../services/ChainApi";
import UserService from "../../services/UserService";
import ChainSymbolAddress from "./chainSymbolAddress";
import ChainSymbolTx from "./chainSymbolTx";
import ChainSymbolToken from "./chainSymbolToken";

export default {
  name: "chainSwitchSmall",
  components: {ChainSymbolToken, ChainSymbolTx, ChainSymbolAddress, ChainSymbolAddressNoLink},
  props: {
    address: String,
    tx: String,
    token: String,
  },
  data() {
    return {
      UserService: UserService,
      ChainApi: ChainApi,
      toggle: {
        select: false,
        notSupported: false,
      },
      ethereum: null,
      provider: null,
      connectedNetwork: null,

      // Mainnet
      ethereumHomestead: ChainApi.getAllowedNetworkSettings.mainnet.ethereum,
      polygon: ChainApi.getAllowedNetworkSettings.mainnet.polygon,
      binanceSmartChain: ChainApi.getAllowedNetworkSettings.mainnet.binanceSmartChain,

      // Testnet
      ethereumRopsten: ChainApi.getAllowedNetworkSettings.testnet.ethereumRopsten,
      ethereumKintsugi: ChainApi.getAllowedNetworkSettings.testnet.ethereumKintsugi,
      ethereumLocal: ChainApi.getAllowedNetworkSettings.testnet.ethereumLocal,
      polygonMumbai: ChainApi.getAllowedNetworkSettings.testnet.polygon,
      binanceSmartChainTestnet: ChainApi.getAllowedNetworkSettings.testnet.binanceSmartChain,
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      this.ethereum = window.ethereum;
      this.ethereum.on('accountsChanged', () => {
        // console.log(accounts)
        this.logout()
        window.location.reload();
        // Time to reload your interface with accounts[0]!
      })
      this.ethereum.on('chainChanged', () => {
        // console.log(networkId)
        // this.load()
        window.location.reload();
        // Time to reload your interface with the new networkId
      })
      this.provider = await this.ChainApi.connectMetaMask()
      this.connectedNetwork = await this.provider.getNetwork()
      // console.log('connectedNetwork', this.connectedNetwork)
    },

    async logout() {
      await this.UserService.logout();
      this.provider = null
      this.signer = null
      this.username = ""
      this.signatedNonce = null
      this.publicAddress = null
      this.identicon = null
      this.connectedNetwork = null
      this.toggleAccountInfos = false
    },

    // toHex(num) {
    //   return '0x' + num.toString(16)
    // },

    async switchChains(chainData) {

      try {
        await this.ethereum.request({
          method: 'wallet_switchEthereumChain',
          // hier hex string der chainId holen und mit "0x" prefixen : https://string-functions.com/string-hex.aspx
          params: [{chainId: chainData.chainId}], // matic main net
          // params: [{chainId: '0x80001'}], // try testnet matic
          // params: [{chainId: '0xf00'}], // demo

        });
        this.toggle = false
      } catch (switchError) {
        console.log('switchError', switchError)
        // This error code indicates that the networkSettings has not been added to MetaMask.
        if (switchError.code === 4902) {
          // Quelle für EVM CHain date Parameter zum Adden
          // https://chainid.network/chains.json
          try {
            await this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [chainData],

            });
            this.toggle = false
          } catch (addError) {
            // handle "add" error
            console.error('addError', addError)
          }
        }
        // handle other "switch" errors
      }
    },

  },
}
</script>

<style scoped>
.switchWrapper {
  width: fit-content;
  /*position: relative;*/
  display: flex;
  justify-content: space-evenly;
  /*flex-direction: column;*/
  align-items: center;
  gap: 18px;
}

.chainSwitcher {
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
  background-color: black;
  padding: 14px;
  border-radius: 50px;
  /*position: absolute;*/
  display: flex;
  justify-content: space-evenly;
  /*flex-direction: column;*/
  align-items: center;
  gap: 18px;
}
</style>