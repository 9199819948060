import axios from "axios"
import router from "../router";
import Api from "./Api";

class UserService {

    async registerUser(publicAddress) {
        const username = prompt('Please Fill in your Name')
        const bio = prompt('Say something about You! (BIO)')
        const response = await axios.post(
            Api.baseUrl + '/users',
            {
                name: username,
                bio: bio,
                publicAddress: publicAddress
            })
        // console.log(response.data);
        await router.push("/");
        // location.reload();
        return response.data[0]
    }

    async authUser(signature, publicAddress) {

        const response = await axios.post(Api.baseUrl + '/authenticate', {
            signature: signature,
            publicAddress: publicAddress
        })

        if (response.data.session.loggedin === true) {
            console.log(response.data.session)
            localStorage.setItem("isUser", response.data.session.loggedin)
            localStorage.setItem("userId", response.data.session.userId)
            // location.reload();
            // await router.push("/");
            window.location.reload();
            // location.reload();
            return response.data.session
        }

    }

    async logout() {
        await axios.get(Api.baseUrl + '/logout')
        localStorage.removeItem("isUser");
        localStorage.removeItem("userId");
        window.location.replace("/");

    }

    softLogout() {
        localStorage.removeItem("isUser");
        // router.push("/");
        // location.reload();
    }

    async checkSession() {

        const response = await axios.get(Api.baseUrl + '/checkSession')
        return response.data.session
    }

    check() {
        // console.log(localStorage.getItem('isUser'))
        return localStorage.getItem('isUser');
    }

    guest() {
        return !this.check()
    }

    async getUser(id) {
        const response = await axios.get(Api.baseUrl + '/users/' + id)
        return response.data[0]
    }

    async getUserByPublicAddress(publicAddress) {
        const response = await axios.get(Api.baseUrl + '/users/account/' + publicAddress)
        return response.data[0]
    }

    async signMessage(nonce, signer, publicAddress) {
        const signedNonce = await signer.signMessage(nonce)
        await this.authUser(signedNonce, publicAddress)
    }

}

export default new UserService()