<template>
  <span>
    <span v-if="chainId === ChainApi.getNetworkId.mainnet.ethereum" class="smallBtn">
      <img src="@/assets/eth.svg" alt="" title="Explore on BlockChain">
    </span>
    <span v-if="chainId === ChainApi.getNetworkId.testnet.ethereumRopsten" class="smallBtn">
      <img src="@/assets/eth_ropsten.svg" width="30px" title="Explore on BlockChain">
    </span>
    <span v-if="chainId === ChainApi.getNetworkId.testnet.ethereumKintsugi" class="smallBtn">
      <img src="@/assets/eth_kintsugi.svg" width="30px" title="Explore on BlockChain">
    </span>
    <span v-if="chainId === ChainApi.getNetworkId.testnet.ethereumLocal" class="smallBtn">
      <img src="@/assets/eth_local.svg" width="30px" title="Explore on BlockChain">
    </span>


    <span v-if="chainId === ChainApi.getNetworkId.mainnet.polygon" class="smallBtn">
      <img src="@/assets/polygon_matic.svg" alt="" title="Explore on BlockChain">
    </span>
    <span v-if="chainId === ChainApi.getNetworkId.testnet.polygon" class="smallBtn">
      <img src="@/assets/polygon_matic_mumbai.svg" alt="" title="Explore on BlockChain">
    </span>
    <span v-if="chainId === ChainApi.getNetworkId.mainnet.binanceSmartChain" class="smallBtn">
              <img src="@/assets/binance.svg" alt="" title="Binance Smart Chain">
    </span>
    <span v-if="chainId === ChainApi.getNetworkId.testnet.binanceSmartChain" class="smallBtn">
              <img src="@/assets/binance_testnet.svg" alt="" title="Binance Smart Chain Testnet">
    </span>
  </span>

</template>

<script>
import ChainApi from "../../services/ChainApi";

export default {
  name: "chainSymbolAddressNoLink",
  props: {
    chainId: Number,
  },
  data() {
    return {
      ChainApi: ChainApi
    }
  }
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';

.smallBtn {
  width: 54px;
  height: 54px;
  //img {
  //  width: 80%;
  //  height: 80%;
  //}
}
</style>