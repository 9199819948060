<template>
  <a :href="nftMeta.metaLink" target="_blank"
     @click="copy(nftMeta.uri, 'you are redirected to the ipfs Gateway')" class="smallBtn">
    <img src="@/assets/ipfsMeta.svg" alt="" title="Pin File on ipfs">
  </a>

</template>

<script>
export default {
  name: "ipfsProofMeta",
  props: {
    nftMeta: Object
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    copy(string, text) {
      navigator.clipboard.writeText(string);
      alert(text + ': ' + string)
    }
  }

}
</script>

<style scoped>

</style>